import React, { useState } from "react";
import BackToLoginButton from "../../components/back-to-login-button";
import LoginHeader from "../../components/login-header";
import DialogSuccess from "./components/dialog-success";
import EnterNewPassword from "./components/enter-new-password-component";

const RecoverPassword = ({ location = {} }) => {
  const email = location?.state?.email || "";
  const [step, setStep] = useState("");
  const handleSetStep = (value) => {
    setStep(value);
  };
  const renderSwitch = (param) => {
    switch (param) {
      case "Success":
        return <DialogSuccess />;
      default:
        return <EnterNewPassword handleSetStep={handleSetStep} email={email} />;
    }
  };
  return (
    <div className="signup-container">
      <LoginHeader />
      <div className="recover-password__container">{renderSwitch(step)}</div>
      <div className="back-to-login-button-container">
        <BackToLoginButton />
      </div>
    </div>
  );
};

export default RecoverPassword;
